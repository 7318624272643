import React, { Fragment, useEffect, useState } from 'react'
import Input from 'components/Input';
import styles from "./mfa.module.scss"
import { sendMfaOpt, verifyMfaOpt } from 'repositories/user-repository';
import { withTranslation } from 'react-i18next';
import Loader from 'components/Loader';
import { config, options, parameters } from 'services/authProvider';
import { MsalAuthProvider } from 'react-aad-msal';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function MultifactorAuth({ t, accountInfo }) {
    const history = useHistory();
    const [seconds, setSeconds] = useState({ emailSeconds: 30, smsSeconds: 30 });
    const [otp, setOpt] = useState('');
    const [errorMsg, setError] = useState(null);
    const [otpData, setOtpData] = useState(null);
    const token = localStorage.getItem("msal.idtoken");
    const [loader, setLoader] = useState(false);
    const [verificationCount, setVerificationCount] = useState(null);
    const [isAlternateMethod, setAlternateMethod] = useState(false);
    const [mobileNo, setMobileNo] = useState('');

    useEffect(() => {
        const countdown = setInterval(() => {
            setSeconds(prev => {
                if (!otpData?.isPhoneActive) {
                    return {
                        ...prev,
                        emailSeconds: prev.emailSeconds > 0 ? prev.emailSeconds - 1 : 0
                    };
                } else {
                    return {
                        ...prev,
                        smsSeconds: prev.smsSeconds > 0 ? prev.smsSeconds - 1 : 0
                    };
                }
            });
        }, 1000);
        return () => clearInterval(countdown);
    }, [!otpData?.isPhoneActive]);

    useEffect(() => { sendOtp(); }, []);

    const logout = async () => {
        try {
            localStorage.clear();
            sessionStorage.clear();
            const authProvider = new MsalAuthProvider(config, parameters, options);
            await authProvider.logout();
            localStorage.setItem('logout-from-mfa', true);
        } catch (error) {
            console.error("Error during logout process:", error);
        }
    };

    const goBack = () => {
        if (!isAlternateMethod && !otpData?.isPhoneActive) {
            logout();
        } else {
            if (isAlternateMethod) {
                setAlternateMethod(false);
            }
            if (otpData?.isPhoneActive) {
                setAlternateMethod(true);
                setOtpData(prev => ({ ...prev, isPhoneActive: false }));
                setError(prev => ({ ...prev, otpSmsError: '' }));
                setMobileNo("");
                setOpt('');
            }
        }
    }

    const detectBrowser = (userAgent) => {
        if (userAgent.includes('Chrome')) return 'chrome';
        if (userAgent.includes('Safari')) return 'Safari';
        if (userAgent.includes('Firefox')) return 'firefox';
        if (userAgent.includes('Edge')) return 'edge';
        if (userAgent.includes('Opera') || userAgent.includes('OPR')) return 'opera';
        return 'unknown';
    };

    const detectPlatform = (userAgent) => {
        if (userAgent.includes('Win')) return 'windows';
        if (userAgent.includes('Mac')) return 'macOS';
        if (userAgent.includes('Linux')) return 'Linux';
        if (userAgent.includes('Android')) return 'android';
        if (userAgent.includes('iPhone') || userAgent.includes('iPad')) return 'iOS';
        return 'unknown';
    };

    async function sendOtp() {
        const userAgent = navigator?.userAgent;
        const browser = detectBrowser(userAgent);
        const platform = detectPlatform(userAgent);
        const deviceType = /Mobi|Android|iPhone|iPad|iPod|BlackBerry|Opera Mini/i.test(userAgent) ? 'mobile' : 'desktop';
        try {
            setLoader(true);

            const deviceId = sessionStorage.getItem('deviceId') || generateUniqueId();
            const deviceTypeString = sessionStorage.getItem('deviceType') || `${deviceType}-${platform}-${browser}`;
            sessionStorage.setItem('deviceId', deviceId);
            sessionStorage.setItem('deviceType', deviceTypeString);
            const forSms = isAlternateMethod || otpData?.isPhoneActive;
            const params = { deviceId, deviceType: deviceTypeString, ...(forSms && { contactNumber: mobileNo }) };
            const res = await sendMfaOpt(params, token, forSms);
            if (res?.data?.mfaType === 1) {
                setSeconds(prev => ({ ...prev, emailSeconds: 30 }));
            } else {
                setSeconds(prev => ({ ...prev, smsSeconds: 30 }));
            }
            if (res?.statusCode !== 200) {
                if (isAlternateMethod) {
                    setError(prev => ({ ...prev, phoneError: res?.message }));

                } else {
                    if (res?.data?.mfaType === 1) {
                        setError(prev => ({ ...prev, otpEmailError: res?.message }));
                        setVerificationCount(prev => ({
                            ...prev,
                            emailData: {
                                ...prev?.emailData,
                                resendAttempts: res?.data?.resendAttempts + 1,
                                wrongAttempts: res?.data?.wrongAttempts + 1
                            }
                        }))
                    } else {
                        setError(prev => ({ ...prev, otpSmsError: res?.message }));
                        setVerificationCount(prev => ({
                            ...prev,
                            phoneData: {
                                ...prev?.phoneData,
                                resendAttempts: res?.data?.resendAttempts + 1,
                                wrongAttempts: res?.data?.wrongAttempts + 1
                            }
                        }))
                    }
                }

            } else {
                setError(prev => ({ ...prev, phoneError: '' }));
                setAlternateMethod(false);
                if (res?.data?.mfaType === 1) {
                    setOtpData(prev => ({ ...prev, emailData: res?.data, isPhoneActive: false }));
                    setVerificationCount(prev => ({
                        ...prev,
                        emailData: {
                            ...prev?.emailData,
                            resendAttempts: res?.data?.resendAttempts,
                            wrongAttempts: res?.data?.wrongAttempts
                        }
                    }))
                } else {
                    setOtpData(prev => ({ ...prev, phoneData: res?.data, isPhoneActive: true }));
                    setVerificationCount(prev => ({
                        ...prev,
                        phoneData: {
                            ...prev?.phoneData,
                            resendAttempts: res?.data?.resendAttempts,
                            wrongAttempts: res?.data?.wrongAttempts
                        }
                    }))
                }
            }
        } catch (err) {
            setLoader(false);
        } finally {
            setLoader(false);
        }
    }


    function generateUniqueId() {
        return '_' + Math.random().toString(36).substr(2, 9);
    }

    const formatTime = (totalSeconds) => {
        const minutes = Math.floor(totalSeconds / 60);
        const remainingSeconds = totalSeconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}`;
    };

    const handleInputChange = (e) => {
        if (/^\d*$/.test(e.target.value)) {
            setOpt(e.target.value);
        }
    };

    const handleMobileNo = (e) => {
        if (/^\d*$/.test(e.target.value)) {
            setMobileNo(e.target.value);
        }
    };


    const veryifyOtp = async () => {
        let errorMsg = '';
        if (!otp.trim().length) {
            errorMsg = t("form.errors.emptyField", {
                field: t("otp"),
            })
        } else if (otp?.length !== 6) {
            errorMsg = 'Otp must be 6 digit'
        }
        if (errorMsg) {
            setError(prev => ({ ...prev, otpError: errorMsg }));

        } else {
            setError('');
        }
        if (!errorMsg) {
            try {
                setLoader(true);
                let params = null;
                if (!otpData?.isPhoneActive) {
                    params = {
                        UserMfaHistoryId: otpData?.emailData?.id,
                        Code: otp
                    }
                } else {
                    params = {
                        UserMfaHistoryId: otpData?.phoneData?.id,
                        Code: otp
                    }
                }

                const res = await verifyMfaOpt(params, token);
                if (!res?.data?.isMfaVerified) {
                    if (res?.data?.mfaType === 1) {
                        setError(prev => ({ ...prev, otpEmailError: res?.message }));
                        setVerificationCount(prev => ({
                            ...prev,
                            emailData: {
                                ...prev?.emailData,
                                wrongAttempts: prev?.emailData?.wrongAttempts + 1
                            }
                        }))
                    } else {
                        setError(prev => ({ ...prev, otpSmsError: res?.message }));
                        setVerificationCount(prev => ({
                            ...prev,
                            phoneData: {
                                ...prev?.phoneData,
                                wrongAttempts: prev?.emailData?.wrongAttempts + 1
                            }
                        }))
                    }
                }
                if (res?.data?.isMfaVerified) {
                    sessionStorage.clear();
                    localStorage.setItem("mfa-validate", true);
                    history.push('/Offices');
                    window.location.reload();
                }
            } catch (err) {
                setLoader(false);

            } finally {
                setLoader(false);
            }
        }
    }

    const handleResend = () => {
        sendOtp()
        if (!otpData?.isPhoneActive) {
            setVerificationCount(prev => ({
                ...prev,
                emailData: {
                    ...prev?.emailData,
                    resendAttempts: prev?.phoneData?.resendAttempts + 1
                }
            }))
        } else {
            setVerificationCount(prev => ({
                ...prev,
                phoneData: {
                    ...prev?.phoneData,
                    resendAttempts: prev?.phoneData?.resendAttempts + 1
                }
            }))
        }
    }

    const isAtemptsFailedForEmail = verificationCount?.emailData?.resendAttempts > 3 || verificationCount?.emailData?.wrongAttempts > 3;
    const isAtemptsFailedForPhone = verificationCount?.phoneData?.resendAttempts > 3 || verificationCount?.phoneData?.wrongAttempts > 3;
    const totalAttemsLeft = !!otpData ? 3 - (!otpData?.isPhoneActive ? otpData?.emailData?.resendAttempts : otpData?.phoneData?.resendAttempts) : 0;

    return (
        <div className='p-2'>
            {loader && <Loader />}
            <div className={styles.mfa_container}>
                <div className={styles.mfa_left_side}>
                    <div className={styles.logo}>
                        <img alt="logo" src="https://mxhhstagingstorageacc.blob.core.windows.net/auth/miraxis-logo.svg" />
                    </div>
                    <div className={styles.signup_block_link}>
                        <img src='https://mxhhstagingstorageacc.blob.core.windows.net/auth/login-img.png' alt='' />
                    </div>
                </div>
                <div className={styles.mfa_right_side}>
                    <div className={styles.field_group}>
                        <button className="back-btn" onClick={goBack}>
                            <div className="back-btn-text">
                                <span className="ico">
                                    <img
                                        src={require("assets/images/arrow-back-icon.svg").default}
                                        alt="arrow"
                                    />
                                </span>
                                {(!isAlternateMethod && !otpData?.isPhoneActive) ? t('mfaAuthenticate.backToLogin') : t('mfaAuthenticate.back')}
                            </div>
                        </button>
                        {!isAlternateMethod ? <Fragment>
                            <h2 className={styles.title}>{!otpData?.isPhoneActive ? t("mfaAuthenticate.emiltTitle") : t("mfaAuthenticate.phoneTitle")}</h2>
                            <p className={styles.sub_title}>{!otpData?.isPhoneActive ? t("mfaAuthenticate.emailSubTitle", { email: accountInfo?.account?.idToken?.email }) : t("mfaAuthenticate.phoneSubTitle", { mobileNo: mobileNo })}</p>
                            <Input
                                Type="text"
                                Placeholder={t("mfaAuthenticate.enterOtp")}
                                HandleChange={handleInputChange}
                                Name='otp'
                                Error={!otpData?.isPhoneActive ? errorMsg?.otpEmailError : errorMsg?.otpSmsError}
                                Value={otp}
                                MaxLength={6}
                                Disabled={!otpData?.isPhoneActive ? isAtemptsFailedForEmail : isAtemptsFailedForPhone}
                            />
                            <div className={styles.buttons}>
                                <button id="next" tabindex="3" onClick={veryifyOtp} disabled={!otpData?.isPhoneActive ? isAtemptsFailedForEmail : isAtemptsFailedForPhone}>{t("mfaAuthenticate.submit")}</button>
                            </div>
                            <div className='mt-5'>
                                <div className={styles.verification_container}>
                                    <div className={styles.btn_time}>
                                        <button className={styles.resend_btn + " " + "link-btn"} disabled={(!otpData?.isPhoneActive ? seconds?.emailSeconds > 0 : seconds?.smsSeconds > 0) || totalAttemsLeft === 0} onClick={handleResend}>{t("mfaAuthenticate.resend")}</button>
                                        <p className={styles.verification_text}>{t("mfaAuthenticate.verifcationCode")}  <span>{formatTime(!otpData?.isPhoneActive ? seconds?.emailSeconds : seconds?.smsSeconds)}</span></p>
                                    </div>
                                    <p className={styles.verification_text}>{totalAttemsLeft} {t("mfaAuthenticate.attemptLeft")}</p>
                                </div>
                                <hr />
                                {!otpData?.isPhoneActive && <div className={styles.verification_container}>
                                    <p className={styles.verification_text}>{t("mfaAuthenticate.unableToAccessEmail")}</p>
                                    <div className={styles.btn_time}>
                                        <button className={styles.resend_btn + " " + "link-btn"} onClick={() => { setAlternateMethod(true); setOpt(''); }}>{t("mfaAuthenticate.tryAlternateMethod")}</button>
                                    </div>
                                </div>}
                            </div>
                        </Fragment> :
                            <Fragment>
                                <h2 className={styles.title}>{t("mfaAuthenticate.enterMobileNo")}</h2>
                                <p className={styles.sub_title}>{t("mfaAuthenticate.mobileMsg")}</p>
                                <Input
                                    Type="text"
                                    Placeholder={t("mfaAuthenticate.enterNumber")}
                                    Name='mobileNo'
                                    HandleChange={handleMobileNo}
                                    Value={mobileNo}
                                    MaxLength={10}
                                    Error={errorMsg?.phoneError}
                                />
                                <div className={styles.buttons}>
                                    <button id="next" tabindex="3" onClick={sendOtp} >{t("mfaAuthenticate.sendCode")}</button>
                                </div>
                            </Fragment>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withTranslation()(MultifactorAuth);